import * as React from "react"
import {graphql} from "gatsby";
import {faDownload, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InnerLayout from "../../components/global/innerLayout"
import parse from "html-react-parser";
import "./pressRelease.scss"

export const query = graphql`
    query ($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "pressRelease", id: $id) {
          ... on Craft_pressRelease_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            title
            uri
            url
            pressReleasePageHeadline
            pageSubtitle
            pressReleasePdf {
              id
              url
            }
            pressReleaseDate
            pressReleaseBody
            pressReleaseContacts {
            ... on Craft_pressReleaseContact_default_Entry {
                id
                title
                pressReleaseContactType
                pressReleaseContactInfo
              }
            }
            prev(section: "pressRelease", orderBy: "pressReleaseDate desc") {
              key: id
              slug
            }
            next(section: "pressRelease", orderBy: "pressReleaseDate desc") {
              key: id
              slug
            }
          }
        }
      }
    }
  `

const PressReleasePage = ({ data, pageContext }) => {

    const getPressReleaseContacts = () => {
        return data.craft.page.pressReleaseContacts.map(contact => {
            return <div className="press-release-contact">
                        <h3>{contact.pressReleaseContactType}</h3>
                        {parse(contact.pressReleaseContactInfo)}
                    </div>
        })
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd press-release-page">
                <div className="content-page">
                    <div className="container-small">
                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                <a className="email" href={`mailto:?subject=${data.craft.page.pressReleasePageHeadline}&body=${data.craft.page.url}`}><FontAwesomeIcon icon={faEnvelope} /></a>

                                {data.craft.page.pressReleasePdf && data.craft.page.pressReleasePdf.length > 0 &&
                                    <a className="download" href={data.craft.page.pressReleasePdf[0].url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDownload} /></a>
                                }
                            </div>
                            <div className="content-right">
                                <div className="breadcrumb">
                                    <ul>
                                        <li><a href="/about/press-releases">Press Releases</a></li>
                                    </ul>
                                </div>
                                <div className="press-release-title">
                                    <h1>{data.craft.page.pressReleasePageHeadline}</h1>
                                    <p>{data.craft.page.pageSubtitle}</p>
                                </div>
                                <div className="press-release-body">
                                    {parse(data.craft.page.pressReleaseBody)}
                                </div>
                                <div className="press-release-contacts">
                                    {getPressReleaseContacts()}
                                </div>
                                <div className="press-release-links">
                                    {data.craft.page.next &&
                                        <a href={`/press-release/${data.craft.page.next.slug}`} className="previous">{`< Previous Press Release`}</a>
                                    }
                                    {data.craft.page.prev &&
                                        <a href={`/press-release/${data.craft.page.prev.slug}`} className="next">{`Next Press Release >`}</a>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default PressReleasePage
